.successCard {
  position: relative;
}
.successCard::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: 0.5s ease all;
}
.successCard .successCardMedia {
  transition: 0.5s ease all;
  width: 100%;
}
.successCard:hover .successCardMedia {
  transform: scale(1.05);
}
.masked.animated {
  animation: maskFade 3s 2s 1 forwards ease-out;
}

.masked {
  display: block;
  width: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
  mask-image: url(./2779948.svg);
  mask-mode: alpha;
}
@keyframes maskFade {
  10% {
    mask-size: 150%;
  }
  20% {
    mask-size: 200%;
  }
  30% {
    mask-size: 250%;
  }
  40% {
    mask-size: 300%;
  }
  50% {
    mask-size: 350%;
  }
  60% {
    mask-size: 400%;
  }
  70% {
    mask-size: 450%;
  }
  80% {
    mask-size: 500%;
    mask-position: center bottom;
  }
  90% {
    mask-size: 550%;
    mask-position: center bottom;
  }
  100% {
    mask-size: 600%;
    mask-position: bottom;
    mask-image: none;
  }
}

.wave-section {
  position: relative;
  overflow: hidden;
}

.wave-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: rotate(180deg);
}

.floating-card {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.scroll-reveal {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s ease-out;
}

.scroll-reveal.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-glow {
  position: relative;
  overflow: hidden;
}

.card-glow::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  transform: translate(-75%, -75%);
  pointer-events: none;
  animation: glow 3s infinite;
}

@keyframes glow {
  0% {
    transform: translate(-75%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-75%, -75%) rotate(360deg);
  }
}
