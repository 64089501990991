@font-face {
  font-family: Bellefair;
  src: url('./assets/font/Bellefair/Bellefair-Regular.ttf') format('truetype');
  font-display: swap;
}
/* @font-face {
  font-family: 'Bellefair';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/static/Bellefair-Regular.ttf') format('truetype');
} */

* {
  font-family: Bellefair !important;
}

.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__canvas {
  box-shadow: 0 0 50px rgba(128, 128, 128, 0.2);
}
.pdf-container {
  width: 90vw;
}
@media print {
  .pdf-container {
    display: none;
  }
}
